import React from "react";
import InfoPage from "../InfoPage";

function CountryInfo() {
  return (
      <InfoPage />
  );
}

export default CountryInfo;
