import React from 'react'
import Countries from '../Countries'

function SearchCountry() {
  return (
    <Countries />
  )
}

export default SearchCountry
